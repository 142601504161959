<template>
  <component :is="tag" class="title">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Title',

  props: {
    level: {
      type: String,
      default: '1'
    }
  },

  computed: {
    tag () {
      return `h${this.level}`
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  background: url(#{$img-path}common/title_bg.png) no-repeat center bottom;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 9px;
}
</style>
