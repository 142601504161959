<template>
<div class="vote-modal">
  <div class="modal-inner">
    <div
      class="bg-content"
      ref="light"
    />
    <div class="main-content">
      <img
        :src="heroImageSrc"
        alt=""
        width="320"
        class="image"
      >
      <h2 class="message-box" v-html="$t('vote005', { hero_name: heroName })">
        <!--
        @FIXME もしMS側の書き換えが可能ならそうする
        <span class="text1">{{ heroName }}</span>
        <span class="text2" v-html="'に投票しますか？'" />
        -->
      </h2>
      <div class="action-buttons">
        <animate-button
          @click="hide"
          class="button -no"
        >
          <img
            :src="require(`@images/vote/${this.locale}/confirm-btn_no.png`)"
            :alt="$t('vote006')"
            width="284"
            height="132"
          >
        </animate-button>
        <animate-button
          @click="vote"
          class="button -yes"
        >
          <img
            :src="require(`@images/vote/${this.locale}/confirm-btn_yes.png`)"
            :alt="$t('vote007')"
            width="284"
            height="132"
          >
        </animate-button>
      </div>
      <p class="note" v-html="$t('vote013')" />
    </div>
  </div>
</div>
</template>

<script>
import Api from '@libraries/api/'
import RequestVote from '@requests/vote'
import { gsap } from 'gsap'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { sleep } from '@libraries/util'
import AnimateButton from '@components/global/button-wrapper'

export default {
  name: 'VoteModal',

  components: {
    AnimateButton
  },

  data () {
    return {
      tls: {},
      isVoting: false
    }
  },

  computed: {
    ...mapState('modal', ['modal']),
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('vote', {
      voteIds: 'getIds',
      isLastVote: 'isLastVote',
      isVoteEnd: 'isVoteEnd'
    }),

    locale () {
      return this.$route.params.lang
    },

    heroId () {
      return this.modal.props.id
    },

    heroName () {
      return this.modal.props.name
    },

    seriesId () {
      return this.modal.props.seriesId
    },

    heroImageSrc () {
      if (!this.modal.props) {
        return
      }
      return require(`@images/hero/faces/lossless/${this.modal.props.id}.png`)
    },
  },

  mounted () {
    const light = this.$refs.light
    this.tls.light = gsap.timeline()
    this.tls.light
      .add([
        gsap.to(light, {
          duration: 20,
          ease: 'none',
          repeat: -1,
          rotation: 360
        }),
        gsap.to(light, {
          duration: 2,
          ease: 'sine.inOut',
          repeat: -1,
          yoyo: true,
          alpha: 0.8
        }),
      ])
  },

  destroyed () {
    Object.values(this.tls).forEach(tl => tl.kill())
  },

  methods: {
    ...mapMutations('vote', { setVoteId: 'setId' }),
    ...mapActions('modal', ['hide']),
    ...mapActions('loader', { showLoader: 'show' }),

    // @TODO voteKeyの要不要を確認(modelにも反映)
    async vote () {
      if (this.isVoting) {
        return
      }
      this.isVoting = true

      await this.showLoader()
      await sleep(this.loaderConfig.minDisplayDuration)

      if (this.isLastVote) {
        // eslint-disable-next-line
        console.log('voted：', this.$route.params.themeType, this.voteIds)
        this.setVoteId(this.heroId)
        const params = {
          themeId: +this.$route.params.themeType,
          heroIds: this.voteIds,
        }
        Api.call(new RequestVote(params))
          .then(() => this.handleSuccess())
          .catch(({error}) => this.handleError(error))
      } else {
        this.setVoteId(this.heroId)
        this.handleSuccess()
      }
    },

    reset () {
      this.isVoting = false
      this.hide()
    },

    handleSuccess () {
      this.$router.push({
        name: 'Voted',
        props: {
          seriesId: this.seriesId,
          heroId: this.heroId
        }
      }, () => {}, )
      this.reset()
    },

    handleError () {
      this.$router.push({ name: 'Error' })
      this.reset()
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/vote.yaml"></i18n>

<style lang="scss" scoped>
.vote-modal {
  width: 722px;
  min-height: 1016px;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 52px;
  }
  &:before {
    background: url(#{$img-path}box/bluebox_bg_top.png) no-repeat center top;
  }
  &:after {
    background: url(#{$img-path}box/bluebox_bg_bottom.png) no-repeat center bottom;
  }
  
  > .modal-inner {
    background: url(#{$img-path}box/bluebox_bg_repeat.png) repeat-y center top;

    > .bg-content {
      position: absolute;
      top: 66px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background: url(#{$img-path}vote/bg_light.png) no-repeat;
      width: 570px;
      height: 572px;
    }
    > .main-content {
      position: relative;
      padding-top: 142px;
      padding-bottom: 20px;
    }
  }
}

.main-content {
  > .image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  > .message-box {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    background: url(#{$img-path}vote/bg_message.png) repeat-y;
    display: flex;
    flex-direction: column;
    text-align: center;// @NOTE 取らないこと
    width: 566px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $color-sub;
    font-size: $fs-base * 1.9;
    line-height: 1.23;

    /* MSを調整するか、細かい調整をやめるか
    > .text1 {
      color: $color-sub;
      font-size: $fs-base * 1.9;
      line-height: 1;
    }
    > .text2 {
      color: $color-sub;
      font-size: $fs-middle;
      line-height: 1;
      letter-spacing: $ls-base;
      margin-top: 14px;
    }
    */
  }

  > .action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 56px;

    > .button + .button {
      margin-left: 20px;
    }
  }

  > .note {
    color: $color-sub;
    text-align: center;
    letter-spacing: 0.025em;
    margin-top: 23px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
</style>
