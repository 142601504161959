export default class Title {
  constructor(json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map(json) {
    this.id = typeof json.id == 'number' ? json.id : null
    this.title = json.title || null
    this.description = json.desc || null
  }

  toJSON() {
    return {
      model_id: this.id,
      model_title: this.title,
      model_description: this.description,
    }
  }
}
