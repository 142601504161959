<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
    class="ripple"
   />
</template>

<script>
import * as PIXI from 'pixi.js'
import { gsap } from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin'

PixiPlugin.registerPIXI(PIXI)
gsap.registerPlugin(PixiPlugin)

export default {
  name: 'RippleCanvas',

  data () {
    return {
      publicPath: process.env.BASE_URL,
      app: null,
      mainTl: null,
      bgLines: null,
      rippleList: null
    }
  },
  props: {
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 400
    },
    voted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    screenWidth () {
      return this.app.screen.width
    },
    screenHeight () {
      return this.app.screen.height
    },
    assets () {
      return [
        { name: 'ripple', url: 'img/vote/bg_ripple.png' },
        { name: 'bg_base', url: `img/common/bg_base${this.voted ? '2' : ''}.png` },
        { name: 'bg_line', url: 'img/common/bg_line.png' }
      ]
    }
  },
  async mounted () {
    await this.loadAssets()
    this.setup()
    this.$emit('loaded')
  },
  destroyed () {
    if (this.mainTl) {
      this.mainTl.kill()
    }
    if (this.app) {
      this.app.destroy(true, { children: true, texture: true, baseTexture: true })
    }
  },
  methods: {
    async loadAssets () {
      return new Promise((resolve) => {
        const loader = new PIXI.loaders.Loader()// v4
        loader.baseUrl = this.publicPath
        loader
          .add(this.assets)
          .load(() => {
            resolve()
          })
      })
    },

    setup () {
      const elm = this.$refs['canvas']
      this.app = new PIXI.Application({
        view: elm,
        width: elm.clientWidth,
        height: elm.clientHeight,
        forceFXAA: true,
        antialias: true,
        transparent: true
      })

      const bgTexture = PIXI.Texture.from('bg_base')
      this.bg = new PIXI.extras.TilingSprite(bgTexture, bgTexture.width, bgTexture.height)
      this.bg.width = this.screenWidth
      this.bg.height = this.screenHeight
      this.app.stage.addChild(this.bg)

      const lineTexture = PIXI.Texture.from('bg_line')
      
      this.bgLines = [
        new PIXI.extras.TilingSprite(lineTexture, lineTexture.width, lineTexture.height),
        new PIXI.extras.TilingSprite(lineTexture, lineTexture.width, lineTexture.height)
      ]

      this.bgLines[0].height = this.bgLines[1].height = this.screenHeight
      this.bgLines[0].x = -5

      this.bg.addChild(this.bgLines[0])
      this.bgLines[1].anchor.x = 1
      this.bgLines[1].position.set(this.screenWidth + 5, 0)
      this.bg.addChild(this.bgLines[1])

      this.rippleList = Array(2).fill().map(() => {
        const ripple = PIXI.Sprite.from('ripple')
        ripple.anchor.set(0.5)
        ripple.position.set(this.screenWidth * 0.5, 400)
        ripple.blendMode = PIXI.BLEND_MODES.SCREEN
        this.app.stage.addChild(ripple)
        return ripple
      })

      this.app.stage.blendMode = PIXI.BLEND_MODES.SCREEN

      this.createTl()
    },

    play () {
      this.mainTl.play()
    },

    stop () {
      this.mainTl.pause()
    },

    createTl () {
      this.mainTl = gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        paused: true
      })

      const duration = 1.1
      const alphaDuration = 0.2

      this.rippleList.forEach((ripple, i) => {
        const tl = gsap.timeline()
        tl
          .add([
            gsap.fromTo(ripple, {
              pixi: {
                scaleX: 0,
                scaleY: 0
              },
            }, {
              pixi: {
                scaleX: 2.4,
                scaleY: 2.4
              },
              duration,
              ease: 'power2.in',
              delay: -0.1,
            }),
            gsap.fromTo(ripple, {
              alpha: 1
            }, {
              alpha: 0,
              duration: alphaDuration,
              delay: duration - alphaDuration,
              ease: 'quad.out',
            })
          ])
          
          this.mainTl.add(tl, i * 0.2)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.canvas {
  width: $base-w;
  height: 400px;
}
</style>