<template>
  <div class="voted">
    <ripple-canvas
      ref="bg-effect"
      :voted="true"
      :height="1100"
      @loaded="handleCanvasLoaded"
      class="effect"
    />
    <div class="main-content">
      <series-title
        level="2"
        class="series-title"
      >
        <img
          :src="titleImageSrc"
          :alt="titleText"
        >
      </series-title>

      <img
        :src="heroImageSrc"
        alt=""
        width="320"
        class="hero-image"
      >

      <div class="hero-name">
        <p class="inner" v-html="$t('vote008', { hero_name: heroName })" />
      </div>

      <div class="action-buttons">
        <back-button @click="goBack()" />
        <action-button
          v-if="canVote"
          buttonColor="gold"
          @click="goToNext()"
          class="action-button"
        >
          <img
            :src="require(`@images/vote/${this.locale}/vote-btn_txt2.png`)"
            :alt="$t('vote011')"
          >
        </action-button>
        <action-button
          v-else
          buttonColor="blue"
          @click="goToMypage()"
          class="action-button"
        >
          <img
            :src="require(`@images/vote/${this.locale}/vote-btn_txt3.png`)"
            :alt="$t('vote010')"
          >
        </action-button>
      </div>
      <p
        v-if="!canVote"
        v-html="$t('modal008')"
        class="note"
      />
    </div>
    <div class="page-footer">
      <sns-share
        shareText="common004"
        :replaceText="replaceText"
      >
        {{ $t('vote009') }}
      </sns-share>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Api from '@libraries/api/'
import RequestHero from '@requests/hero'
import RequestTitles from '@requests/titles'
import SeriesTitle from '@components/global/title.vue'
import BackButton from '@components/global/back-button.vue'
import ActionButton from '@components/global/button.vue'
import NaModal from '@components/modals/na-modal.vue'
import ModalModel from '@models/modal'
import SnsShare from '@components/global/sns-share.vue'
import RippleCanvas from '@components/global/ripple-canvas.vue'

export default {
  name: 'Voted',

  components: {
    SeriesTitle,
    BackButton,
    ActionButton,
    SnsShare,
    RippleCanvas
  },

  props: {
    locale: {
      type: String,
      required: true
    },
    themeType: {
      type: Number,
      required: true
    },
    seriesId: {
      type: Number,
      required: true
    },
    heroId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      hero: null,
      titles: null
    }
  },

  computed: {
    ...mapGetters('login', ['isLogin', 'getVoted']),
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('theme', { currentTheme: 'currentTheme' }),
    ...mapGetters('vote', { isVoteEnd: 'isVoteEnd', votedIds: 'getIds', voteNumber: 'getNumber' }),

    isVoted () {
      return this.getVoted(this.seriesId)
    },

    // 投票履歴で投票済みではない &&
    // 投票が完了していない
    canVote () {
      return !this.isVoted && !this.isVoteEnd
    },

    replaceText () {
      const heroIds = [...(this.votedIds || [])]
      let heroIdsParamStr = ''

      const noneNumber = this.voteNumber - heroIds.length
      if (noneNumber > 0) {
        heroIds.push('none')
      }

      heroIds.forEach((heroId, i) => {
        heroIdsParamStr += `&hero_id${i + 1}=${heroId}`
      })

      const url = `${location.origin}/${this.locale}?theme_id=${this.themeType}${heroIdsParamStr}`

      return {
        theme: this.currentTheme.name,
        url
      }
    },

    titleImageSrc () {
      if (!this.hero) {
        return
      }
      return require(`@images/series/${this.locale}/txt_series_${this.hero.seriesId}.png`)
    },

    title () {
      if (!this.titles) {
        return
      }
      return this.titles.find(title => title.id === this.hero.seriesId)
    },

    titleText () {
      if (!this.title) {
        return
      }
      return this.title.title
    },

    heroName () {
      if (!this.hero) {
        return
      }
      return this.hero.name
    },

    heroImageSrc () {
      if (!this.hero) {
        return
      }
      return require(`@images/hero/faces/lossless/${this.hero.id}.png`)
    },
  },

  created () {
    
  },

  async mounted () {
    this.canvas = this.$refs['bg-effect']

    await Promise.all([
      await this.loadCanvasAssets(),
      await this.getHero(),
      await this.getTitles(),
      await sleep(this.loaderConfig.minDisplayDuration)
    ])

    this.canvas.play()

    this.$nextTick(async () => {
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('modal', { showModal: 'show' }),
    ...mapActions('loader', { hideLoader: 'hide' }),

    async loadCanvasAssets () {
      return new Promise(resolve => {
        this.canvasResolve = resolve
      })
    },

    handleCanvasLoaded () {
      this.canvasResolve()
    },

    async getHero() {
      await Api.call(new RequestHero({ locale: this.locale, heroId: this.heroId }))
        .then((hero) => {
          this.hero = hero
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    async getTitles () {
      await Api.call(new RequestTitles({ locale: this.locale }))
        .then((titles) => {
          this.titles = titles
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    // 該当シリーズの一覧に戻る
    goBack () {
      this.$router.push({ name: 'HeroList' })
    },

    // マイページへ
    goToMypage () {
      if (this.isLogin) {
        // this.$router.push({ name: 'Mypage' })
        location.href = `/${this.locale}/mypage`
      } else {
        this.showModal(new ModalModel({
          component: NaModal,
          props: {
            type: 'mypage'
          }
        }))
      }
    },

    // ２人目の投票へ
    goToNext () {
      this.$router.push({ name: 'Series' })
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/vote.yaml"></i18n>
<i18n lang="yaml" src="@/locales/modal.yaml"></i18n>

<style lang="scss" scoped>
.voted {
  background:
    url(#{$img-path}common/bg_line_l.png) repeat-y left top,
    url(#{$img-path}common/bg_line_r.png) repeat-y right top,
    url(#{$img-path}common/bg_base2.png) repeat-y center top;
  padding-top: 151px;

  > .effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  > .main-content {
    position: relative;
    padding-bottom: 67px;
  }
  > .page-footer {
  }
}

  .main-content {
    > .series-title {
    }
    > .hero-image {
      display: block;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
    }
    > .hero-name {
      background:
        url(#{$img-path}vote/name_bg_top.png) no-repeat center top,
        url(#{$img-path}vote/name_bg_bottom.png) no-repeat center bottom;
      width: 578px;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .hero-name > .inner {
      background: url(#{$img-path}vote/name_bg_middle.png) repeat-y center top;
      color: $color-base;
      font-size: $fs-large;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 68px;
      $padding: (13em / strip-unit($fs-large)) / 10;
      padding-top: $padding;
      padding-bottom: $padding;
      width: 100%;
      text-align: center;// @NOTE 取らないこと
    }

    > .action-buttons {
      margin-top: 55px;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      width: 700px;

      > .back-button {
        margin-top: 2px;
      }
      > .action-button {
        width: 544px;

        /deep/ .inner {
          margin-left: -76px;
          margin-right: -76px;
        }
      }
    }

    > .note {
      margin-top: 25px;
      color: $color-base;
      font-size: $fs-middle;
      text-align: center;
      padding-left: 52px;
      padding-right: 52px;
    }
  }
  .page-footer {
  }
</style>
