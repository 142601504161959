import { render, staticRenderFns } from "./voted.vue?vue&type=template&id=61f96dd3&scoped=true&"
import script from "./voted.vue?vue&type=script&lang=js&"
export * from "./voted.vue?vue&type=script&lang=js&"
import style0 from "./voted.vue?vue&type=style&index=0&id=61f96dd3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f96dd3",
  null
  
)

/* custom blocks */
import block0 from "@/locales/vote.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fviews%2Fvoted.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/modal.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fviews%2Fvoted.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports