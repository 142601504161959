import Request, { Methods } from '@libraries/request/'
import ModelTitle from '@models/title'
import { basePath } from './config'

export default class Titles extends Request {
  constructor(options) {
    super()
    this.locale = options.locale || null
  }

  get path() {
    return basePath + 'json/titles.json'
  }

  get method() {
    return Methods.get
  }

  get parameters() {
    const params = {}
    return params
  }

  decode({ data }) {
    const obj = {}
    for (const i in data[this.locale]) {
      const _i = i.split('.')
      const titleId = _i[0].replace('game','')
      const text = _i[1]
      obj[titleId] = obj[titleId] || {}
      obj[titleId][text] = data[this.locale][i]
    }
    let res = []
    for (const i in obj) {
      const item = obj[i]
      item.id = Number(i)
      res.push(item)
    }
    return res.map(r => new ModelTitle(r))
  }

  decodeError(error) {
    return { error: error }
  }
}