import Request, { Methods } from '@libraries/request/'

export default class Vote extends Request {
  constructor(options) {
    super()
    this.themeId = options.themeId
    this.heroIds = options.heroIds
  }

  get path() {
    return '/api/v1.0.0/vote'
  }

  get method() {
    return Methods.post
  }

  get parameters() {
    const params = {}
    params.theme_id = this.themeId
    params.hero_ids = this.heroIds
    return params
  }

  decode({ data }) {
    return data
  }

  decodeError(error) {
    return { error: error }
  }
}