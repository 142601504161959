import Request, { Methods } from '@libraries/request/'
import ModelHero from '@models/hero'
import { basePath } from './config'

export default class Hero extends Request {
  constructor(options) {
    super()
    this.locale = options.locale || null
    this.heroId = options.heroId || null
  }

  get path() {
    return basePath + 'json/heroes.json'
  }

  get method() {
    return Methods.get
  }

  get parameters() {
    const params = {}
    return params
  }

  decode({ data }) {
    const _data = {}
    _data.hero_id = Number(this.heroId)
    _data.name = data[this.locale][this.heroId]
    const hero = new ModelHero(_data, this.locale)
    return hero
  }

  decodeError(error) {
    return { error: error }
  }
}